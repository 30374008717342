<template>
  <div class="content-info">
    <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700" :text="showText" :is-full-screen="true" />

    <div class="honor-content">
      <!-- <div class="honor-item" v-for="(item,key) in honorList" :key="key">
        <div class="honor-img" :style="{ backgroundImage: `url(${item.img})` }"></div>
        <div class="honor-title">{{ item.title }}</div>
      </div> -->
      <viewer :images="honorList" class="images">
      <template slot-scope="scope">
       <div class="honor-item" v-for="(item,key) in scope.images" :key="key">
          <!-- <div class="honor-img" :style="{ backgroundImage: `url(${item.img})` }"></div> -->
          <div class="honor-img-block"><img class="honor-img" :src="item.imgUrl" :alt="item.honorTitle" /></div>
          <div class="honor-title">{{ item.honorTitle }}</div>
        </div>
        <!-- <img :src="item.img"> -->
      </template>

        <!-- <template slot-scope="scope">
            <img  v-for="(item,key) in honorList" :key="key"
                :src="src" :key="src" class="image">
          </template> -->
      </viewer>

      <div class="pagination-content">
        <Pagination :currentPage="currentPage" :total="total" :pageSize="params.limit" :onPageChange="changePage" />
      </div>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/about/honor";

export default {
  name: 'AboutHonor',
  computed: {
    // 当前页，从查询参数 page 返回
    currentPage() {
      return parseInt(this.$route.query.page) || 1
    }
  },
  data() {
    return {
      show:false,
      showText:'',
      params:{
        page:1,
        limit:10
      },
      honorList:[],
      total: 0,
    }
  },
  created() {
    // this.navLinkList.push({ name:'荣誉资质',link:''})
    this.params.page =  parseInt(this.$route.query.page) || 1
    this.getHonnorList()
  },
  methods: {
     // 回调，组件的当前页改变时调用
    changePage(page) {
      // 在查询参数中混入 page，并跳转到该地址
      this.$router.push({ query: { ...this.$route.query, page } })
    },
    getHonnorList() {
      this.show = true;
      this.showText = "加载中...";
      getList(this.params)
        .then(res => {
          if (res.code !== 1) {
            console.log(res.msg);
          } else {
            this.honorList = res.data
          }
          this.show = false;
        })
        .catch(err => {
          console.log(err);
          this.show = false;
        });
    },
  }
}
</script>

<style scoped>
  .honor-content {
    padding:40px 10%;
  }

  .honor-item {
    display: inline-block;
    width:19%;
    margin:10px 0.5%;

    border: solid 3px #f5f5f5;
    position: relative;
    padding: 1.5vw 1vw 1.5vw;
    transition: 0.3s;
    
  }

  .honor-img-block {
    height: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .honor-img {
    /* padding-bottom:80%;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center center; */
    max-width:100%;
    max-height:100%;
  }

  .honor-title{
    /* font-size: 16px; */
    /* line-height: 28px; */
    /* height: 52px; */
    text-align: center;
    margin-top: 20px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-box-orient: vertical;
    min-height:28px;
  }
  
  @media (max-width: 1024px) {
    .honor-item {
      width:48%;
    }

    .honor-img-block {
      height: 20vw;
    }

  }

  @media (max-width: 768px) {
    .honor-content {
      padding:10px;
    }
  }
</style>